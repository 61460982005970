
  import {
    defineComponent,
    ref,
    inject,
    reactive,
    onMounted,
    nextTick,
    computed,
  } from "vue";
  import fixedHeader from "@/components/common/Header.vue";
  import Toast from "@/components/UI/Toast";
  import api from "@/api/axios";
  import { useRouter, useRoute } from "vue-router";


  export default defineComponent({
    name: "Login",
//   components: {
//     fixedHeader,
//   },
    emits: ["loginsuccess"],
    setup(props, ctx) {
      // const hide = inject("handleHide");

      // function handleHide() {
      //   (hide as () => void)();
      // }
      const router = useRouter();
      const route = useRoute();
      const isActive = ref(0);
      function handleTab(type: number) {
        isActive.value = type;
      }

      const isweixin = ref(false);
      const ua = navigator.userAgent.toLowerCase();
      const reg = /MicroMessenger/i;
      isweixin.value = reg.test(ua);

      //code 登录
      const telphone = ref<string>("");
      const code = ref<string>("");
      const passCode = ref<string>("");
      const password = ref<string>("");
      const sendCodeFlag = ref<boolean>(true);
      const codeText = ref<string>("获取验证码");
      let timer: any = ref(null);
      const count = ref(60);

      function checkTel(tel: string) {
        if (tel === "") {
          Toast({
            title: "请输入手机号码",
            type: "warning",
          });
          return false;
        }
        const retel = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/;
        if (tel.length != 11 || !retel.test(tel)) {
          Toast({
            title: "请输入正确的手机号码",
            type: "warning",
          });
          // $('#tel').focus();
          return false;
        }
        return true;
      }

      //获取验证码
      function getSMSCode() {
        if (!checkTel(telphone.value)) {
          return false;
        }
        if (!passCode.value) {
          Toast({
            title: "请输入图形验证码",
            type: "warning",
          });
          return false;
        }
        if( !sendCodeFlag.value ){
          return false
        }
        sendCodeFlag.value = false;
        /**
         *  获取验证码
         * */
        const formData = new FormData();
        formData.append("tel", telphone.value);
        formData.append("option", "registerOpt");
        formData.append("passcode", passCode.value);
        api
            .post("/M/Server/GetNewSMSCode", formData)
            .then((res) => {
              if (res.data.success) {
                Toast({
                  title: "验证码发送成功，请注意查收",
                  type: "success",
                });
                count.value = 60;
                codeText.value = count.value + "s";
                timer = setInterval(function () {
                  count.value--;
                  if (count.value == 0) {
                    clearInterval(timer);
                    sendCodeFlag.value = true;
                    codeText.value = "获取验证码";
                  } else {
                    codeText.value = count.value + "s";
                  }

                }, 1000);
              } else {
                sendCodeFlag.value = true;
                Toast({
                  title: res.data.msg,
                  type: "error",
                });
              }
            })
            .catch(() => {
              // Toast({
              //     title: '未知错误，请联系客服',
              //     type: "success"
              // })
            });
        // count.value = 60;
        // codeText.value = count.value + "s";
        // timer = setInterval(function () {
        //    count.value--;
        //   if (count.value == 0) {
        //     clearInterval(timer);
        //     sendCodeFlag.value = true;
        //     codeText.value = "获取验证码";
        //   } else {
        //     codeText.value = count.value + "s";
        //   }

        // }, 1000);
      }


      //  登录成功处理
      const UPDATE_USERINFO = inject("UPDATE_USERINFO") as (data: object) => void;
      function updateToken(token: string) {
        localStorage.setItem("token", token);
      }

      function handleUserInfo(data: object) {
        UPDATE_USERINFO(data);
      }
      const codeImg = ref("/M/Server/GetVerifyCodeImg");
      function getCodeImg() {
        codeImg.value = "/M/Server/GetVerifyCodeImg?random=" + Math.random();
      }

      //验证码登录
      function handleCodeLogin() {
        const formData = new FormData();
        formData.append("username", telphone.value);
        formData.append("smscode", code.value);
        formData.append("passcode", passCode.value);
        api
                .post("/M/Server/DoLoginWidthCode", formData)
                .then((res) => {
                  if (res.data.success) {
                    updateToken(res.data.token);
                    handleUserInfo(res.data.obj);
                    //  清除购物车的guid
                    //  localStorage.removeItem('guid')
                    Toast({
                      title: "登录成功！",
                      type: "success",
                    });


                    if (String(res.data.msg).trim() == "请设置密码!") {
                      router.push({ name: "SetPassword" })
                      sessionStorage.setItem("telphone", telphone.value)
                      return false;
                    } else {
                      // handleHide();
                      ctx.emit("loginsuccess");
                    }
                    const redirect = (route.query.redirect ? route.query.redirect : "/") as string;
                    router.push(redirect)
                  } else {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                    });
                  }
                })
                .catch(() => {
                  // Toast({
                  //   title: "未知错误，请联系客服",
                  //   type: "success",
                  // });
                });
      }

      //密码登录
      function handlePassLogin() {
        const formData = new FormData();
        formData.append("username", telphone.value);
        formData.append("password", password.value);
        api
                .post("/M/Server/DoLoginWidthPassword", formData)
                .then((res) => {
                  if (res.data.success) {
                    updateToken(res.data.token);
                    handleUserInfo(res.data.obj);
                    //  清除购物车的guid
                    //  localStorage.removeItem('guid')
                    Toast({
                      type: "success",
                      title: res.data.msg,
                      onClose: () => {
                        // handleHide();
                        ctx.emit("loginsuccess");
                      },
                    });
                    const redirect = (route.query.redirect ? route.query.redirect : "/") as string;
                    router.push(redirect)
                  } else {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                    });
                  }
                })
                .catch(() => {
                  // Toast({
                  //   title: "未知错误，请联系客服",
                  //   type: "success",
                  // });
                });
      }

      function changeNum() {
        if(code.value.length > 6){
          code.value = code.value.slice(0, 6)
        }
      }


      function weixinLogin(){
        const fData = new FormData();
        const redirect = (route.query.redirect ? route.query.redirect : "/mine") as string;
        fData.append("returnurl", window.location.origin + redirect);
        api
                .post("/M/User/WeixinLogin", fData)
                .then((res) => {
                  if (res.data.success) {
                    //微信登录直播间刷新
                    localStorage.setItem('WX','WX')
                    res.data.returnUrl=res.data.returnUrl.replace('reloaded',"")
                    window.location.href = res.data.returnUrl;
                    // api.get(res.data.returnUrl).then(d => {
                    //   const data = d.data.obj;
                    //   if(data.token){
                    //     updateToken(data.token);
                    //     handleUserInfo(data.obj);
                    //     Toast({
                    //       type: "success",
                    //       title: res.data.msg,
                    //       onClose: () => {
                    //         ctx.emit("loginsuccess");
                    //       },
                    //     });
                    //     const redirect = (route.query.redirect ? route.query.redirect : "/") as string;
                    //     router.push(redirect)
                    //   }else{
                    //     if(data.unionid){
                    //       router.push({ name: "InitTel" })
                    //     }else{
                    //       Toast({
                    //         type: "warning",
                    //         title: "请先关注北纳生物公众号"
                    //       });
                    //     }
                    //   }
                    // })
                  } else {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                    });
                  }
                })
                .catch(() => {
                  // Toast({
                  //   title: "未知错误，请联系客服",
                  //   type: "success",
                  // });
                });
      }

      return {
        //   handleHide,
        isActive,
        handleTab,
        isweixin,
        telphone,
        code,
        password,
        codeText,
        getSMSCode,
        handleCodeLogin,
        handlePassLogin,
        changeNum,
        weixinLogin,
        codeImg,
        getCodeImg,
        passCode
      };
    },
  });
